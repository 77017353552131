<template>
  <div class="bg-light" style="padding-top:100px;">
    <div class="container">
      <div class="main-container col-md-10">
        <h5 style="margin-left:30px;">Hireclap Jobseeker Communications</h5>
      </div>
      <div class="col-md-10 col-md-offset-1 main-container mt-5">
        <p>Dear <span class="linkcolor">Candidate</span>, you can update your communications with Hireclap on this page. Enter your email and check your subscription status.</p>
        <div class="col-md-6" id="check_subscription">
          <form autocomplete="off">
            <label>Enter Your Email/Mobile Number:</label>
            <input type="text" placeholder="Enter Your Email/Mobile" class="form-control col-md-6" value="" name="email" id="email" autocomplete="off" required="">
            <div class="email_error"></div>
            <div class="col-md-12">	
              <button type="button" class="btn btn-md btn-primary" name="fetch_unsub_details" id="fetch_unsub_details">Check Subscriptions</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default ({

})
</script>

<style scoped>
.main-container{border: 1px solid #eee;box-shadow: 2px 2px 2px #eee;background: #fff;margin-left: 8.33333333333333% !important;}
</style>